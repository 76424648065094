import * as React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { ISystemType } from 'src/service/OrgTypes'
import FolderMetadataRow from '../../../document/components/Folders/CreateFolderModal/FolderMetadataRow'
import { IMetaData } from '../../../document/types/IMetaData'

interface ISystemTypeFieldGroupProps {
  control: Control<FieldValues, ISystemType>
}

const SystemTypeFieldsGroup: React.FC<ISystemTypeFieldGroupProps> = ({
  control,
}) => {
  const { t } = useTranslation()

  return (
    <Controller
      render={({ field: { value, onChange } }) => (
        <div className="py-2">
          {!!value?.length && (
            <>
              <p className="px-2 font-medium text-sm">{t('metadata')}</p>
            </>
          )}
          <div className="flex flex-col gap-6">
            {value?.map((field: IMetaData) => {
              const onFieldChange = (
                fieldId: string,
                updatedMetaData: Partial<IMetaData> & {
                  set_default_values_on_empty_values?: boolean
                  set_default_values_on_all?: boolean
                },
              ) => {
                const index = value.findIndex(
                  (meta: IMetaData) => meta.id === fieldId,
                )
                value[index] = updatedMetaData
                onChange(value)
              }
              return (
                <div key={field.id}>
                  <FolderMetadataRow
                    field={field as unknown as IMetaData}
                    updateMetadata={onFieldChange}
                    disabled
                  />
                  <div className={'flex w-full mt-2'}>
                    <div className={'w-1/2 pl-2 flex items-center'}>
                      <CheckBox
                        disable={false}
                        onChange={(value) =>
                          onFieldChange(field.id, {
                            ...field,
                            set_default_values_on_empty_values: value,
                          })
                        }
                        valueProp={value['set_default_values_on_empty_values']}
                      />
                      <label className={'ml-2 text-black font-normal text-sm'}>
                        {t('set_default_values_on_empty_values')}
                      </label>
                    </div>
                    <div className={'w-1/2 pl-2 flex items-center'}>
                      <CheckBox
                        onChange={(value) =>
                          onFieldChange(field.id, {
                            ...field,
                            set_default_values_on_all: value,
                          })
                        }
                        valueProp={value['set_default_values_on_all']}
                      />
                      <label className={'ml-2 text-black font-normal text-sm'}>
                        {t('set_default_values_on_all')}
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      name={'optional_fields'}
      control={control}
    />
  )
}

export default SystemTypeFieldsGroup
